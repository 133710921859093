<template>
    <div class="main-content">

        <h4 class="section_title">{{ $t('account_info') }}</h4>
        <div class="text-center">
        <img src="../../../assets/images/profile/person.png" alt="" height="90px" width="90px">
        <div class="">
            <div class="rank_username font-weight-700 mt-2 mb-1">
                {{ $root.userInfo.username }}
            </div>
            <div class="user-info mb-4">
                {{ $root.userInfo.country.country_code }}{{ $root.userInfo.contact_number }} |
                {{ $root.userInfo.email }}
            </div>

        </div>

        </div>
        <b-card class="">
            <div v-for="item in info" :key="item">
                <b-row class="recordDetail">
                    <b-col cols="4" class="">{{ $t(item) }}</b-col>
                    <b-col cols="8" class="text-right text-primary2 font-weight-600 text-16 "
                        style="text-transform: none;">
                        <!-- text-nowrap -->
                        <span v-if="item == 'contact_number'">{{ $root.userInfo.country.country_code }}</span>
                        {{ $root.userInfo[item] }}
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <h4 class="section_title mt-4">{{ $t('general') }}</h4>
        <b-card v-for="(item, index) in actions" :key="item" class="mb-2" @click="checkPath(item)">
            <div class="d-flex justify-content-between align-items-center">
                <img :src="require('../../../assets/images/profile/Icon-' + index + '.png')" alt="" height="30px"
                    width="30px">
                <span class="ml-3 text-secondary text-16 font-weight-600 text-left text-capitalize">{{ $t(item)
                    }}</span>
                <span v-if="item == 'kyc'" class="flex-grow-1 text-right font-weight-bold mr-2 text-14"
                    :class="kycStatusStyle">{{ $t(kycStatusText) }}</span>
                <span v-else class="flex-grow-1 text-right font-weight-bold"></span>
                <span v-if="item == 'customer_support' && $root.admin_replied > 0" class="circle-box font-weight-bold">
                    {{ $root.admin_replied }}
                </span>
                <span><i class="fa fa-chevron-right"></i></span>

            </div>
        </b-card>

        <div class="logout" @click="logout"><span class="text-24"><i class="fa fa-sign-out"></i></span> {{
            $t('sign_out') }}</div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            actions: [
                'kyc',
                'security',
                'bank-information',
               
            ],
            info: ['member_code', 'email', 'contact_number'],
            kycStatusStyle: '',
            kycStatusText: ''
        }
    },
    watch: {
        '$root.userInfo': 'checkStatus'
          
    },
    methods: {
        logout() {
            localStorage.removeItem('currencyToken');
            this.$router.push('/web/sessions/login');
        },
        checkStatus() {
            const kyc = this.$root.userInfo?.check_kyc?.can_withdraw || 'unverified'; 

            switch (kyc) {
                case 'failed':
                    
                    if (this.$root.userInfo.check_kyc.failed_reason) {
                        this.kycStatusText = 'rejected';
                        this.kycStatusStyle = 'text-danger';
                    }else {
                        this.kycStatusText = 'unverified';
                        this.kycStatusStyle = 'text-info';
                    }
                    break;
                case 'pending':
                    this.kycStatusText = 'pending';
                    this.kycStatusStyle = 'text-warning';
                    break;
                case 'approved':
                    this.kycStatusText = 'verified';
                    this.kycStatusStyle = 'text-success';
                    break;
                default:
                    this.kycStatusText = 'unverified';
                    this.kycStatusStyle = 'text-info';
                    break;
            }


        },
        checkPath(path) {
            if (path == 'kyc') {
                var kyc = this.$root.userInfo.check_kyc.can_withdraw;
                if (kyc == 'failed' || kyc == 'approved')
                    this.$router.push('/web/profile/' + path);
            }
            else if (path == 'bank-information'){
                this.$router.push('/web/profile/' + path);
            }
            else  
                this.$router.push('/web/profile/' + path);

        }
    },
    mounted() {
    if (this.$root.userInfo.check_kyc) {
        this.checkStatus();
    }
},

}
</script>